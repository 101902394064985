import Echo from "laravel-echo"
import Pusher from "pusher-js"
import type { INotification } from "~/interfaces/support/iNotification"
import { useNotification } from "~/stores/notification"

export const usePusher = () => {
  const { data } = useAuth()
  const { reverb, apiUrl } = useRuntimeConfig().public
  const { token } = useAuth()
  const store = useNotification()
  const toast = useToaster()
  window.Pusher = Pusher
  const socket = new Echo({
    broadcaster: "reverb",
    encrypted: false,
    key: reverb.appKey,
    wsHost: reverb.host,
    wsPort: reverb.port ?? 80,
    wssPort: reverb.port ?? 443,
    forceTLS: (reverb.scheme ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authEndpoint: `${apiUrl}/api/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `${token.value}`,
      },
    },
  })
  const channel = socket.channel(`private-notification.${data.value?.id}`)
  channel
    .listen(
      ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (e: INotification) => {
        toast
          .show({
            title: "success",
            color: "success",
            closable: true,
            message: "Notification",
            icon: "gg:check-o",
          })
          .then((r) => {
            store.notificationChange = true
          })
      },
    )
    .error(async (e: object) => {
      console.error()
      toast.show({
        title: "success",
        color: "success",
        closable: true,
        message: "Private channel error :" + e,
        icon: "gg:check-o",
      })
    })
}
